<template>
  <!-- eslint-disable vue/no-static-inline-styles -->
  <page-container class="monitoring-settings" :title="$t('pages.titles.monitoringSettings')">
    <fictitious-login-form />

    <div class="monitoring-settings__content">
      <titled-card title="Настройки отправителя уведомлений по почте">
        <base-form
          v-model="formModels[FormName.NOTIFICATIONS_SENDER]"
          class="grid-container"
          :disabled="isPending"
          :configuration="formConfigurations[FormName.NOTIFICATIONS_SENDER]"
        />

        <template #actions>
          <v-btn
            color="secondary"
            :disabled="areActionsButtonsDisabled[FormName.NOTIFICATIONS_SENDER]"
            small
            depressed
            @click="resetSettings(FormName.NOTIFICATIONS_SENDER)"
          >
            Отменить
          </v-btn>

          <v-btn
            class="ml-3"
            color="primary"
            :disabled="areActionsButtonsDisabled[FormName.NOTIFICATIONS_SENDER]"
            :loading="pendingTasks[FormName.NOTIFICATIONS_SENDER]"
            small
            depressed
            @click="saveSettings(FormName.NOTIFICATIONS_SENDER)"
          >
            Применить
          </v-btn>
        </template>
      </titled-card>

      <titled-card title="Настройки отправителя уведомлений по SMS (twilio.com)">
        <base-form
          v-model="formModels[FormName.SMS_SENDER]"
          :disabled="isPending"
          :configuration="formConfigurations[FormName.SMS_SENDER]"
        />

        <template #actions>
          <v-btn
            color="secondary"
            :disabled="areActionsButtonsDisabled[FormName.SMS_SENDER]"
            small
            depressed
            @click="resetSettings(FormName.SMS_SENDER)"
          >
            Отменить
          </v-btn>

          <v-btn
            class="ml-3"
            color="primary"
            :disabled="areActionsButtonsDisabled[FormName.SMS_SENDER]"
            :loading="pendingTasks[FormName.SMS_SENDER]"
            small
            depressed
            @click="saveSettings(FormName.SMS_SENDER)"
          >
            Применить
          </v-btn>
        </template>
      </titled-card>

      <titled-card title="Контакты получателя уведомлений">
        <base-form
          v-model="formModels[FormName.NOTIFICATIONS_RECEIVER]"
          :disabled="isPending"
          :configuration="formConfigurations[FormName.NOTIFICATIONS_RECEIVER]"
        />

        <template #actions>
          <v-btn
            color="secondary"
            :disabled="areActionsButtonsDisabled[FormName.NOTIFICATIONS_RECEIVER]"
            small
            depressed
            @click="resetSettings(FormName.NOTIFICATIONS_RECEIVER)"
          >
            Отменить
          </v-btn>

          <v-btn
            class="ml-3"
            color="primary"
            :disabled="areActionsButtonsDisabled[FormName.NOTIFICATIONS_RECEIVER]"
            :loading="pendingTasks[FormName.NOTIFICATIONS_RECEIVER]"
            small
            depressed
            @click="saveSettings(FormName.NOTIFICATIONS_RECEIVER)"
          >
            Применить
          </v-btn>
        </template>
      </titled-card>

      <titled-card title="Проверка авторизации">
        <base-form
          v-model="formModels[FormName.RTS_CREDENTIALS]"
          :disabled="isPending"
          :configuration="formConfigurations[FormName.RTS_CREDENTIALS]"
        />

        <template #actions>
          <v-btn
            color="secondary"
            :disabled="areActionsButtonsDisabled[FormName.RTS_CREDENTIALS]"
            small
            depressed
            @click="resetSettings(FormName.RTS_CREDENTIALS)"
          >
            Отменить
          </v-btn>

          <v-btn
            class="ml-3"
            color="primary"
            :disabled="areActionsButtonsDisabled[FormName.RTS_CREDENTIALS]"
            :loading="pendingTasks[FormName.RTS_CREDENTIALS]"
            small
            depressed
            @click="saveSettings(FormName.RTS_CREDENTIALS)"
          >
            Применить
          </v-btn>
        </template>
      </titled-card>

      <titled-card>
        <template #title>
          <div class="grid-container pr-4">
            <div class="block-subtitle" style="grid-column: 1 / 7">Расписание проверок</div>
            <div style="grid-column: 7 / 9; justify-self: center">Интервал повтора</div>
          </div>
        </template>

        <v-form class="grid-container" :disabled="isPending">
          <div class="mb-details" style="grid-column: 1 / 7">Доступность электронной площадки</div>
          <number-input
            v-model="formModels[FormName.MONITORING_SCHEDULE].healthcheck_interval_hours"
            style="grid-column: 7 / 8"
            label="Часы"
            :min="0"
            :max="23"
            dense
            filled
          />
          <number-input
            v-model="formModels[FormName.MONITORING_SCHEDULE].healthcheck_interval_minutes"
            style="grid-column: 8 / 9"
            label="Минуты"
            :min="0"
            :max="59"
            dense
            filled
          />

          <div class="mb-details" style="grid-column: 1 / 7">Формирование границ РВУ</div>
          <number-input
            v-model="formModels[FormName.MONITORING_SCHEDULE].plot_creation_interval_hours"
            style="grid-column: 7 / 8"
            label="Часы"
            :min="0"
            :max="23"
            dense
            filled
          />
          <number-input
            v-model="formModels[FormName.MONITORING_SCHEDULE].plot_creation_interval_minutes"
            style="grid-column: 8 / 9"
            label="Минуты"
            :min="0"
            :max="59"
            dense
            filled
          />

          <div class="mb-details" style="grid-column: 1 / 7">Доступность РТС-тендер</div>
          <number-input
            v-model="formModels[FormName.MONITORING_SCHEDULE].rts_healthcheck_interval_hours"
            style="grid-column: 7 / 8"
            label="Часы"
            :min="0"
            :max="23"
            dense
            filled
          />
          <number-input
            v-model="formModels[FormName.MONITORING_SCHEDULE].rts_healthcheck_interval_minutes"
            style="grid-column: 8 / 9"
            label="Минуты"
            :min="0"
            :max="59"
            dense
            filled
          />

          <div class="mb-details" style="grid-column: 1 / 7">Авторизация в РТС-тендер</div>
          <number-input
            v-model="formModels[FormName.MONITORING_SCHEDULE].rts_authorization_interval_hours"
            style="grid-column: 7 / 8"
            label="Часы"
            :min="0"
            :max="23"
            dense
            filled
          />
          <number-input
            v-model="formModels[FormName.MONITORING_SCHEDULE].rts_authorization_interval_minutes"
            style="grid-column: 8 / 9"
            label="Минуты"
            :min="0"
            :max="59"
            dense
            filled
          />
        </v-form>

        <template #actions>
          <v-btn
            color="secondary"
            :disabled="areActionsButtonsDisabled[FormName.MONITORING_SCHEDULE]"
            small
            depressed
            @click="resetSettings(FormName.MONITORING_SCHEDULE)"
          >
            Отменить
          </v-btn>

          <v-btn
            class="ml-3"
            color="primary"
            :disabled="areActionsButtonsDisabled[FormName.MONITORING_SCHEDULE]"
            :loading="pendingTasks[FormName.MONITORING_SCHEDULE]"
            small
            depressed
            @click="saveSettings(FormName.MONITORING_SCHEDULE)"
          >
            Применить
          </v-btn>
        </template>
      </titled-card>
    </div>
  </page-container>
</template>

<script>
  import _ from 'lodash'
  import { parse as parseISODuration, toSeconds as durationToSeconds } from 'iso8601-duration'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { objectFromKeys } from 'geoportal/src/utils/func'
  import { showMessage } from '@/utils/messages'
  import { createFormConfiguration, formFieldPresets } from '@/components/BaseForm/formHelpers'
  import { emailRule, formatTextInputValue } from '@/utils/forms'

  const FormName = Object.freeze({
    NOTIFICATIONS_RECEIVER: 'notificationsReceiver',
    NOTIFICATIONS_SENDER: 'notificationsSender',
    RTS_CREDENTIALS: 'rtsCredentials',
    MONITORING_SCHEDULE: 'schedule',
    SMS_SENDER: 'smsSender'
  })
  const formConfigurations = {
    [FormName.NOTIFICATIONS_RECEIVER]: createFormConfiguration([
      [
        { id: 'receiver_email', label: 'Почта', bind: { autocomplete: 'username', rules: [emailRule] } },
        formFieldPresets.text
      ],
      [{ id: 'receiver_phone', label: 'Телефон', bind: { autocomplete: 'tel' } }, formFieldPresets.text]
    ]),
    [FormName.NOTIFICATIONS_SENDER]: createFormConfiguration([
      [
        { id: 'host', label: 'Хост', style: { gridColumn: '1 / 5' }, bind: { autocomplete: 'url' } },
        formFieldPresets.text
      ],
      [{ id: 'port', label: 'Порт', style: { gridColumn: '5 / 7' } }, formFieldPresets.text],
      [
        {
          id: 'protocol',
          label: 'Протокол',
          style: { gridColumn: '7 / 9' },
          bind: {
            items: ['Plain', 'TLS', 'STARTTLS'].map((protocol) => ({
              text: protocol,
              value: protocol.toLowerCase()
            }))
          }
        },
        formFieldPresets.select
      ],
      [
        {
          id: 'login',
          label: 'Почта',
          style: { gridColumn: '1 / 5' },
          bind: { type: 'email', autocomplete: 'username', rules: [emailRule] }
        },
        formFieldPresets.text
      ],
      [{ id: 'password', label: 'Пароль', style: { gridColumn: '5 / 9' } }, formFieldPresets.password]
    ]),
    [FormName.RTS_CREDENTIALS]: createFormConfiguration([
      [{ id: 'rts_test_login', label: 'Логин РТС-тендер', bind: { autocomplete: 'username' } }, formFieldPresets.text],
      [{ id: 'rts_test_password', label: 'Пароль РТС-тендер' }, formFieldPresets.password]
    ]),
    [FormName.SMS_SENDER]: createFormConfiguration([
      [{ id: 'sms_user_id', label: 'Идентификатор пользователя (Account Sid)' }, formFieldPresets.text],
      [{ id: 'sms_token', label: 'Токен (Auth Token)' }, formFieldPresets.text],
      [{ id: 'sms_phone_number', label: 'Телефон', bind: { autocomplete: 'tel' } }, formFieldPresets.text]
    ])
  }

  export default {
    name: 'MonitoringSettingsView',
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        FormName,
        formConfigurations,

        initialSettings: objectFromKeys(Object.values(FormName), () => ({})),
        formModels: objectFromKeys(Object.values(FormName), () => ({}))
      }
    },

    computed: {
      hasNotChanges() {
        return Object.values(FormName).reduce((acc, formName) => {
          acc[formName] = _.isEqual(this.formModels[formName], this.initialSettings[formName])
          return acc
        }, {})
      },

      areActionsButtonsDisabled() {
        return Object.values(FormName).reduce((acc, formName) => {
          acc[formName] = this.isPending || !!this.pendingTasks[formName] || this.hasNotChanges[formName]
          return acc
        }, {})
      }
    },

    created() {
      this.fetchSettings()
    },

    methods: {
      setModels(settings) {
        const monitoringSettings = settings.monitoring

        const formModels = {}
        formModels[FormName.NOTIFICATIONS_RECEIVER] = _.pick(
          monitoringSettings,
          _.map(formConfigurations[FormName.NOTIFICATIONS_RECEIVER], 'id')
        )

        formModels[FormName.NOTIFICATIONS_SENDER] = _.pick(
          monitoringSettings.sender,
          _.map(formConfigurations[FormName.NOTIFICATIONS_SENDER], 'id')
        )

        formModels[FormName.RTS_CREDENTIALS] = _.pick(
          monitoringSettings,
          _.map(formConfigurations[FormName.RTS_CREDENTIALS], 'id')
        )

        formModels[FormName.MONITORING_SCHEDULE] = Object.entries(
          _.pick(monitoringSettings, [
            'healthcheck_interval',
            'plot_creation_interval',
            'rts_healthcheck_interval',
            'rts_authorization_interval'
          ])
        ).reduce((acc, [key, value]) => {
          const durationInSeconds = value ? durationToSeconds(parseISODuration(value)) : 0
          const hours = Math.trunc(durationInSeconds / 3600)
          acc[`${key}_hours`] = hours
          acc[`${key}_minutes`] = (durationInSeconds - hours * 3600) / 60
          return acc
        }, {})

        formModels[FormName.SMS_SENDER] = _.pick(
          monitoringSettings,
          _.map(formConfigurations[FormName.SMS_SENDER], 'id')
        )

        this.initialSettings = _.cloneDeep(formModels)
        this.formModels = formModels
      },

      @handlePendingTask()
      async fetchSettings() {
        const result = await this.$api.backend.settings.get_settings()
        this.setModels(result)
      },

      resetSettings(formName) {
        this.$set(this.formModels, formName, _.cloneDeep(this.initialSettings[formName]))
      },

      async saveSettings(formName) {
        const formData = _.pickBy(
          _.mapValues(this.formModels[formName], formatTextInputValue),
          (value, key) => !_.isEqual(value, this.initialSettings[formName][key])
        )

        let requestData
        switch (formName) {
          case FormName.NOTIFICATIONS_SENDER: {
            requestData = { sender: formData }
            break
          }

          case FormName.MONITORING_SCHEDULE: {
            const formInitialSettings = this.initialSettings[FormName.MONITORING_SCHEDULE]
            requestData = [
              'healthcheck_interval',
              'plot_creation_interval',
              'rts_healthcheck_interval',
              'rts_authorization_interval'
            ].reduce((acc, key) => {
              const hoursKey = `${key}_hours`
              const minutesKey = `${key}_minutes`
              const hours = formData[hoursKey] ?? formInitialSettings[hoursKey]
              const minutes = formData[minutesKey] ?? formInitialSettings[minutesKey]
              // backend can't read long declaration of period so use the shortened one
              acc[key] = `PT${hours}H${minutes}M`
              return acc
            }, {})
            break
          }

          default:
            requestData = formData
        }

        const request = this.$api.backend.settings.update_settings({ monitoring: requestData })
        const result = await this.handlePendingTask(request, formName)
        this.setModels(result)
        showMessage('Настройки успешно обновлены')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .monitoring-settings {
    .monitoring-settings__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 80px 20px;

      @media (max-width: 1600px) {
        grid-template-columns: initial;
      }

      .titled-card {
        display: flex;
        flex-direction: column;

        &::v-deep .v-sheet {
          flex-grow: 1;
          padding-bottom: 4px !important;
        }
      }

      .grid-container {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        gap: 0 12px;
        align-items: center;
      }
    }
  }
</style>
